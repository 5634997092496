<style lang="less" scoped>
.Preview-T-WorkOnline-Module {
  .stu-module-header {
    padding: 20px;
    background: #fff;
    box-shadow: @shadow;
    margin-bottom: 15px;
  }
  .content {
    .red {
      color: #ff0000;
    }
    .green {
      color: #009999;
    }
    .slot_total {
      display: inline-block;
      margin-right: 12px;
      font-weight: bold;
    }
    .items {
      border: 1px solid @borderBottomColor;
      margin-bottom: 20px;
      .header {
        display: flex;
        justify-content: space-between;
        padding: 20px;
        border-bottom: 1px solid @borderBottomColor;
        .h_left {
          span {
            font-weight: bold;
            font-size: 16px;
          }
          span:first-child {
            display: inline-block;
            // margin-right: 10px;
          }
          span:last-child {
            display: inline-flex;
            .h_left_title {
              font-weight: bold;
            }
          }
        }
        .h_right {
          font-weight: bold;
          font-size: 16px;
        }
      }
      @media screen and (max-width: 1200px) {
        .header {
          flex-flow: column;
        }
      }
      .list_ul {
        padding: 20px;
        padding-bottom: 0;
        li {
          padding: 10px 0 10px 12px;
          position: relative;
          &::before {
            content: "";
            position: absolute;
            left: 0;
            top: 50%;
            margin-top: -2px;
            height: 4px;
            width: 4px;
            border-radius: 50%;
            background: #000;
          }
          span {
            font-size: 16px;
          }
        }
      }
      .referAnswer {
        background-color: #f9f9f9;
        padding: 20px;
        border: 1px dashed #d8d8d8;
        overflow: hidden;
        color: #999;
        margin: 0 20px;
        margin-top: 20px;
        font-size: 16px;
      }
      .parse {
        padding: 20px 40px;
        p {
          font-size: 16px;
        }
        .parse_label {
          margin-bottom: 10px;
        }
      }
    }
  }
}
</style>
<template>
  <div class="Preview-T-WorkOnline-Module">
    <div class="stu-module-header">
      <div class="stu-module-title">作业预览</div>
      <el-button @click="back" type="primary">返回</el-button>
    </div>
    <AddInfo noSaveBtn />
    <el-card style="margin-top: 20px;">
      <div slot="header">试卷：</div>
      <div class="content">
        <el-collapse v-model="activeNames">
          <el-collapse-item
            v-for="(item, index) in data"
            :key="index"
            :name="index"
          >
            <template slot="title">
              <span class="slot_total">{{ item.desc }}</span>
            </template>

            <div class="items" v-for="(c, i) in item.bigQuestions" :key="i">
              <div class="header">
                <div class="h_left">
                  <span>{{ c.questionName }}</span>
                  <div>
                    <p v-html="c.title" class="h_left_title"></p>
                  </div>
                </div>
              </div>
              <div v-for="(l, j) in c.questionList" :key="j">
                <span>{{ j + 1 }}、</span><span v-html="l.title"></span>
                <ul class="list_ul" v-for="(q,qindex) in l.options" :key="qindex">
                  <li>
                    <span>{{ q.itemKey }}）</span>
                    <span>{{ q.itemVal }}</span>
                  </li>
                </ul>
                <div class="referAnswer">
                  <span class="green">参考答案：</span>
                  <span v-html="l.questionKey"></span>
                </div>
                <div class="referAnswer">
                  <span class="green">问题解析：</span>
                  <span v-html="l.questionAnalyze"></span>
                </div>
              </div>

            </div>
          </el-collapse-item>
        </el-collapse>
      </div>
    </el-card>
  </div>
</template>

<script>
import AddInfo from "./Add.vue";
import {QueryStudentWorkById} from "../../../../../libs/api/teacher/check";
export default {
  name: "preview-t-workOnline-module",
  components: { AddInfo },
  activated() {
    this.init()
  },
  created() {
  },
  data() {
    return {
      title: "作业要求",
      backUrl: "/teacher/t/work/online",
      data: [],
      active: null,
      form: {
        workName: "",
        paperId: "", // 关联试卷
        workDesc: "", // 作业要求
        rollNum: 10, // 出卷次数
        weight: 0, // 权重
        answerNum: 0, // 答题次数
        answerTime: 90, // 答题时间
        copyFlag: "0", // 禁止复制粘贴
        buttonFlag: "0", // 取消文本框文件按钮
        showFlag: "0", // 显示参考答案
        scoreFlag: "1", // 显示客观题成绩
        historyFlag: "1", // 显示可查看历史作业
        shiftFlag: "0", // 是否允许撤回作答记录
      },
    };
  },
  mounted() {
  },
  computed: {
    activeNames: {
      get() {
        // 展开全部
        return this.active || this.data.map((m, i) => i);
      },
      set(v) {
        this.active = v;
      },
    },
  },
  methods: {

    init(){
      const { id } = this.$route.query;
      this.loading = true;
      this.$axios_supermallData.get("/hnjxjy-core/eduCourseWorkSetTea/previewHomeWorkByWorkId?id="+id)
          .then((res) => {
            this.loading = false;
            if (res.data.code==2000) {
              this.form = res.data.data;
              this.data.push(res.data.data.map)

              for (let i = 0 ; i < this.data.length ; i ++){
                for (let k = 0 ; k < this.data[i].bigQuestions.length ; k++){
                  for (let j = 0 ; j < this.data[i].bigQuestions[k].questionList.length ; j++){
                    this.data[i].bigQuestions[k].questionList[j].questionAnalyze = this.data[i].bigQuestions[k].questionList[j].questionAnalyze.toString().replace(/<[^>]+>/g,"");
                    this.data[i].bigQuestions[k].questionList[j].questionKey = this.data[i].bigQuestions[k].questionList[j].questionKey.toString().replace(/<[^>]+>/g,"");
                    this.data[i].bigQuestions[k].questionList[j].title = this.data[i].bigQuestions[k].questionList[j].title.toString().replace(/<[^>]+>/g,"");
                  }
                }
              }
            }
          })
          .catch((res) => {
                  this.loading = false;
          });
    },
    back() {
      this.$router.push(this.backUrl);
    },
  },
};
</script>
